import React from 'react'
import { Facebook, Twitter } from 'react-feather'
import { FacebookShareButton, TwitterShareButton } from 'react-share'

const Share = ({
  url,
  title,
  twitterHandle,
  twitterHashtags = ['DataFusion', 'DFS', 'DataFusionSpecialists'],
  facebookHashtag = '#DFS',
}) => (
  <div className="post-share">
    <span className="share-title small dark-text">Share article:</span>
    <TwitterShareButton
      url={url}
      title={title}
      via={twitterHandle.split('@').join('')}
      hashtags={twitterHashtags}
      className="with-pointer"
    >
      <span className="icon is-medium with-br-circle with-bg-primary is-link">
        <Twitter size={24} />
      </span>
    </TwitterShareButton>
    <FacebookShareButton
      url={url}
      quote={title}
      via={twitterHandle.split('@').join('')}
      hashtag={facebookHashtag}
      className="with-pointer"
    >
      <span className="icon is-medium with-br-circle with-bg-primary is-link">
        <Facebook size={24} />
      </span>
    </FacebookShareButton>
  </div>
)

export default Share
