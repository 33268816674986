/* eslint-disable react/jsx-one-expression-per-line */
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import PageBuilder from '../blocks'
import FeaturedMedia from '../components/FeaturedMedia'
import Layout from '../components/Layout'
import Share from '../components/Share'
import { decodeHtml, isGatsbyImage, stripHTML } from '../utils/helpers'

const Category = ({ category }) => (
  <span className="tag is-medium is-rounded has-gradient-orange-yellow">
    <strong className="has-text-white">{category.name}</strong>
  </span>
)

export const BlogPostTemplate = ({
  categories,
  tags,
  title,
  date,
  blocks,
  author,
  featuredMedia,
  excerpt,
  url,
  twitterHandle,
}) => {
  return (
    <React.Fragment>
      {featuredMedia && isGatsbyImage(featuredMedia) ? (
        <FeaturedMedia
          featuredMedia={featuredMedia}
          className="blog-thumbnail-container"
        >
          <div className="container content">
            <div className="columns align-items-center is-mobile">
              <div className="column is-two-thirds-desktop is-three-quarters-tablet is-full-mobile m-auto">
                <span className="tag is-medium w-fc has-background-primary">
                  <strong className="has-text-white">Article</strong>
                </span>
                <h1
                  className="title has-text-white mt-6"
                  dangerouslySetInnerHTML={{ __html: title || 'Default title' }}
                />
                {categories &&
                  categories.length &&
                  !categories.some(el => el.name === 'Uncategorized') && (
                    <div>
                      {categories.map(category => (
                        <Category key={category.slug} category={category} />
                      ))}
                    </div>
                  )}
                {author && (
                  <p className="has-text-white is-size-5-mobile is-size-5-tablet is-size-4-widescreen">
                    {author.name} • {date}
                  </p>
                )}
                {excerpt && (
                  <span className="subtitle has-text-white is-size-5-mobile is-size-5-tablet is-size-4-widescreen">
                    {excerpt}
                  </span>
                )}
              </div>
            </div>
          </div>
        </FeaturedMedia>
      ) : featuredMedia ? (
        /* Not Gatsby image, pass in the wordpress media instead */
        <FeaturedMedia
          wordpressMedia={featuredMedia}
          className="blog-thumbnail-container"
        >
          <div className="container content">
            <div className="columns align-items-center is-mobile">
              <div className="column is-two-thirds-desktop is-three-quarters-tablet is-full-mobile m-auto">
                <span className="tag is-medium w-fc has-background-primary">
                  <strong className="has-text-white">Article</strong>
                </span>
                <h1
                  className="title has-text-white mt-6"
                  dangerouslySetInnerHTML={{ __html: title || 'Default title' }}
                />
                {categories &&
                  categories.length > 0 &&
                  !categories.some(el => el.name === 'Uncategorized') && (
                    <div>
                      {categories.map(category => (
                        <Category key={category.path} category={category} />
                      ))}
                    </div>
                  )}
                {date && author && (
                  <p className="has-text-white is-size-5-mobile is-size-5-tablet is-size-4-widescreen">
                    {author.name} • {date}
                  </p>
                )}
                {excerpt && (
                  <span className="subtitle has-text-white is-size-5-mobile is-size-5-tablet is-size-4-widescreen">
                    {excerpt}
                  </span>
                )}
              </div>
            </div>
          </div>
        </FeaturedMedia>
      ) : (
        <div className="blog-thumbnail-container-dark">
          <div className="container content">
            <div className="columns align-items-center is-mobile">
              <div className="column is-two-thirds-desktop is-three-quarters-tablet is-full-mobile m-auto">
                <span className="tag is-medium w-fc has-background-primary">
                  <strong className="has-text-white">Article</strong>
                </span>
                <h1
                  className="title has-text-white mt-6"
                  dangerouslySetInnerHTML={{ __html: title || 'Default title' }}
                />
                {categories &&
                  categories.length > 0 &&
                  !categories.some(el => el.name === 'Uncategorized') && (
                    <div>
                      {categories.map(category => (
                        <Category key={category.path} category={category} />
                      ))}
                    </div>
                  )}
                {date && author && (
                  <p className="has-text-white is-size-5-mobile is-size-5-tablet is-size-4-widescreen">
                    {author.name} • {date}
                  </p>
                )}
                {excerpt && (
                  <span className="subtitle has-text-white is-size-5-mobile is-size-5-tablet is-size-4-widescreen">
                    {excerpt}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <article className="section">
        <div className="container content">
          <div className="columns align-items-center is-mobile">
            <div className="column is-two-thirds-desktop is-three-quarters-tablet is-full-mobile m-auto">
              <div className="post-content">
                <PageBuilder blocks={blocks} />
              </div>
              <div className="space-between mt-16 p-4 has-border-top-bottom br-5 mtb-32">
                <div className="horizontal-list">
                  {tags &&
                    tags.length > 0 &&
                    tags.map(tag => (
                      <span className="tag is-medium is-rounded has-text-white has-background-primary">
                        {tag.name}
                      </span>
                    ))}
                </div>
                {url && (
                  <Share
                    url={url}
                    title={title}
                    twitterHandle={`@${twitterHandle}`}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </article>
    </React.Fragment>
  )
}

BlogPostTemplate.propTypes = {
  title: PropTypes.string,
}

const BlogPost = ({ data }) => {
  const {
    wordpressPost: post,
    site: {
      siteMetadata: { url, twitterHandle, wordpressUrl },
    },
  } = data

  const windowUrl = typeof window !== 'undefined' ? window.location.href : url

  return (
    <Layout
      seo={{
        postData: post,
        postImage:
          post.featured_media && post.featured_media.localFile
            ? post.featured_media.localFile.childImageSharp.fluid.src
            : null,
        isBlogPost: true,
      }}
      lightText
    >
      <BlogPostTemplate
        wordpressUrl={wordpressUrl}
        blocks={post.blocks}
        content={post.content}
        categories={post.categories}
        tags={post.tags}
        title={decodeHtml(post.title)}
        date={post.date}
        author={post.author}
        featuredMedia={post.featured_media}
        excerpt={stripHTML(post.excerpt)}
        url={windowUrl}
        twitterHandle={twitterHandle}
      />
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    site {
      siteMetadata {
        url: siteUrl
        wordpressUrl
        twitterHandle
      }
    }
    wordpressPost(id: { eq: $id }) {
      ...PostFields
      blocks {
        ...WPBlockPost
      }
      categories {
        name
        slug
        path
      }
      tags {
        name
        slug
        path
      }
      author {
        name
        slug
        path
      }
    }
  }
`
